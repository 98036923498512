import { media } from '../../../common/utils/utils';
import uikit from '../../uikit/styles';
const stylesheets = (width, height, variable = {}) => ({
    officeWrapper: {
        margin: '0 auto',
        width: '100%',
        display: 'flex',
        'flex-direction': 'column',
        color: variable.darkColor,
        'background-color': variable.greyColor,
        'overflow-x': 'hidden',
        'flex-grow': 1,
        'min-height': '100vh'
    },
    pageTitle: media(width, height, 'w', {
        768: { display: 'none' },
        575: {
            'font-weight': '500',
            ...uikit.h5,
            color: variable.darkColor,
            padding: '0 20px',
            'text-align': 'center'
        },
        0: {
            'font-weight': '500',
            'font-size': '1.28rem',
            'line-height': '1.44',
            color: variable.blackColor,
            padding: '0 15px',
            'text-align': 'center'
        }
    }),
    officeWrapperNative: {
        'margin-right': 'auto',
        'margin-left': 'auto',
        width: '100%',
        display: 'flex',
        'flex-direction': 'column',
        flex: 1,
        color: variable.darkHalfTransparentColor,
        'background-color': variable.greyColor,
        position: 'relative'
    },
    officeBlocks: {
        display: 'flex',
        'flex-direction': 'row',
        'background-color': variable.greyColor,
        'flex-grow': 1
    },
    officeBlocksNative: {
        display: 'flex',
        'flex-direction': 'column',
        'background-color': variable.greyColor,
        'flex-grow': 1,
        'border-style': 'solid',
        'border-width': '2px',
        'border-color': variable.redDeepColor
    },
    officeContent: {
        position: 'relative',
        width: '100%',
        'z-index': 0
    },
    officeContentWrapper: media(width, height, 'w', {
        1301: {
            'padding-left': '120px',
            position: 'relative',
            display: 'flex',
            'min-height': `${height}px`
        },
        0: {
            'padding-left': 0,
            position: 'relative',
            flex: '1',
            'min-height': '100%',
            display: 'flex',
            'flex-direction': 'column'
        }
    }),
    officeMine: media(width, height, 'w', {
        768: {
            padding: '24px',
            ' flex-grow': '1',
            display: 'flex',
            'flex-direction': 'column'
        },
        0: {
            padding: '0 0 15px',
            'flex-grow': '1',
            display: 'flex',
            'flex-direction': 'column',
            position: 'relative',
            'z-index': '8'
        }
    }),
    officeChildrenWrapper: media(width, height, 'w', {
        // [768]: {
        //     'flex-grow': '0'
        // },
        0: {
            'flex-grow': '1',
            display: 'flex',
            'flex-direction': 'column'
        }
    }),
    officeMineNative: media(width, height, 'w', {
        575: {
            padding: '24px',
            'flex-grow': '1',
            display: 'flex',
            'flex-direction': 'column',
            'min-height': `${height}` - 165
        },
        0: {
            padding: '8px 0 0 0',
            'flex-grow': '1',
            display: 'flex',
            'flex-direction': 'column',
            'min-height': `${height}` - 165
        }
    }),
    officeContentMine: media(width, height, 'w', {
        1301: {
            display: 'flex',
            'flex-direction': 'column',
            'flex-grow': '1',
            width: 'calc(100% - 400px)'
        },
        0: {
            display: 'flex',
            'flex-direction': 'column',
            'flex-grow': '1',
            width: '100%'
        }
    }),
    officeMainMenuBlock: media(width, height, 'w', {
        1301: {
            position: 'fixed',
            width: 0,
            height: '100%'
        },
        0: {
            position: 'fixed',
            top: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            'z-index': '10'
        }
    }),

    officeWidgetBlock: {
        position: 'fixed',
        top: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        'z-index': '10'
    },
    officeMainMenuBlockNative: {
        position: 'absolute',
        top: 0,
        left: '-100%',
        width: '100%',
        height: '100%',
        display: 'flex',
        'z-index': '10'

    },
    officeWidgetBlockNative: {
        position: 'absolute',
        top: 0,
        right: '-100%',
        width: '100%',
        'max-width': '100%',
        height: '100%',
        display: 'flex',
        'align-items': 'flex-end',
        color: variable.whiteColor,
        'z-index': '10'
    },
    NativeWidgetBlockActive: { right: 0 },
    officeMainMenuNative: media(width, height, 'w', {
        768: {
            'background-color': variable.whiteColor,
            height: '100%',
            width: '120px',
            top: 0,
            padding: '7px 0',
            display: 'flex',
            'flex-direction': 'column',
            shadowColor: variable.blackExtraTransparentColor,
            shadowOpacity: 0.35,
            'shadow-radius': '6px',
            elevation: 10
        },

        0: {
            'background-color': variable.whiteColor,
            height: '100%',
            width: '200px',
            top: 0,
            padding: '7px 0',
            'border-top-right-radius': '20px',
            display: 'flex',
            'flex-direction': 'column',
            shadowColor: variable.blackExtraTransparentColor,
            shadowOpacity: 0.35,
            'shadow-radius': '6px',
            elevation: 10,
            flex: 1
        }
    }),
    officeMainMenuShow: media(width, height, 'w', {
        1301: {},
        0: { left: 0 }
    }),
    officeWidgetUser: media(width, height, 'w', {
        1301: {
            position: 'relative',
            right: 0,
            'border-radius': `${'30px'} 0px 0px ${'30px'}`,
            width: '72px',
            'background-color': variable.whiteColor,
            'box-sizing': 'border-box',
            transition: '.3s',
            padding: 0,
            'font-weight': '400',
            ...uikit.text
        },
        575: {
            position: 'absolute',
            'border-radius': `${'30px'} 0px 0px ${'30px'}`,
            width: '400px',
            'background-color': variable.whiteColor,
            'box-sizing': 'border-box',
            transition: '.3s',
            right: 0,
            'max-height': '100%',
            'box-shadow': `6px 4px 25px ${variable.blackExtraTransparentColor}`
        },
        0: {
            position: 'absolute',
            'border-radius': `${'30px'} 0px 0px ${'30px'}`,
            width: '100%',
            'background-color': variable.whiteColor,
            right: 0,
            padding: 0,
            'max-height': '100%',
            'box-shadow': `6px 4px 25px ${variable.blackExtraTransparentColor}`
        }
    }),
    officeWidgetUserNative: media(width, height, 'w', {
        575: {
            position: 'absolute',
            'border-radius': `${'30px'} 0px 0px ${'30px'}`,
            width: '460px',
            'background-color': variable.whiteColor,
            right: 0,
            padding: 0,
            height: '100%'
        },
        0: {
            position: 'absolute',
            'border-radius': `${'30px'} 0px 0px ${'30px'}`,
            width: '100%',
            'background-color': variable.whiteColor,
            right: 0,
            padding: 0,
            height: '100%'
        }
    }),
    officeWidgetUserShow: { right: 0 },
    officeWidgetUserButtonArrow: {
        fill: variable.darkColor,
        width: '20px',
        height: '14px'
    },
    hide: media(width, height, 'w', {
        1300: { display: 'none' },
        0: {}
    }),
    hideMobile: media(width, height, 'w', {
        1300: {},
        0: { display: 'none' }
    }),
    officeWidgetUserButtonNative: media(width, height, 'w', {
        575: {
            width: '48px',
            height: '48px',
            'border-radius': '35px',
            'background-color': variable.whiteColor,
            position: 'absolute',
            display: 'flex',
            'justify-content': 'center',
            'align-items': 'center',
            // 'cursor': 'pointer',
            'z-index': 2,
            top: '16px',
            left: '-24px',
            'border-style': 'solid',
            'border-width': 1,
            'border-color': variable.darkExtraTransparentColor
        },
        0: {
            width: '48px',
            height: '48px',
            'border-radius': '35px',
            'background-color': variable.whiteColor,
            position: 'absolute',
            display: 'flex',
            'justify-content': 'center',
            'align-items': 'center',
            // 'cursor': 'pointer',
            'z-index': 2,
            top: '16px',
            left: '16px',
            'border-style': 'solid',
            'border-width': '1px',
            'border-color': variable.darkExtraTransparentColor
        }
    }),
    officePanel: media(width, height, 'w', {
        768: { flex: 'unset' },
        575: {
            'background-color': variable.whiteColor,
            // padding: '24px',
            'border-radius': '20px',
            flex: '1',
            'margin-bottom': 0
        },
        0: {
            'background-color': variable.whiteColor,
            // padding: '16px',
            'border-radius': '20px',
            flex: '1',
            'margin-bottom': '8px'
        }
    }),
    officePanelWeb: media(width, height, 'w', {
        768: { flex: 'unset' },
        575: { display: 'flex' },
        0: { display: 'flex' }
    }),
    headerLogo: media(width, height, 'w', {
        768: {},
        0: { display: 'none' }
    }),
    officeHeaderNative: {
        display: 'flex',
        'justify-content': 'space-between',
        'flex-direction': 'row',
        'background-color': variable.whiteColor,
        'align-items': 'flex-end',
        height: '88px',
        padding: '36px 16px 16px 16px',
        'border-radius': `0px 0px ${'20px'} ${'20px'}`,
        'margin-bottom': '4px'
    },
    officeHeader: media(width, height, 'w', {
        1301: {
            display: 'flex',
            'justify-content': 'space-between',
            'flex-direction': 'row',
            'align-items': 'center',
            height: '80px',
            padding: '0 24px 0 24px',
            'margin-bottom': 0,
            'background-color': variable.whiteMoreTransparentColor
        },
        575: {
            display: 'flex',
            'justify-content': 'space-between',
            'flex-direction': 'row',
            'align-items': 'center',
            height: '80px',
            padding: '0 12px',
            'margin-bottom': '12px',
            'background-color': variable.whiteColor
        },
        0: {
            display: 'flex',
            'justify-content': 'space-between',
            'flex-direction': 'row',
            'background-color': variable.whiteColor,
            'align-items': 'center',
            height: '60px',
            'margin-bottom': '12px',
            padding: '0 12px',
            'border-radius': `0px 0px ${'20px'} ${'20px'}`
        }
    }),

    officeFooter: media(width, height, 'w', {
        768: {
            display: 'flex',
            'justify-content': 'space-between',
            padding: '12px 24px 24px'
        },
        0: {
            display: 'flex',
            'justify-content': 'center',
            'align-items': 'center',
            'flex-direction': 'column',
            padding: '12px 12px 100px'
        }
    }),
    officeFooterWhite: media(width, height, 'w', {
        768: {},
        0: { 'background-color': variable.whiteColor }
    }),
    officeFooterHidden: media(width, height, 'w', {
        768: {},
        0: { 'opacity': '0', 'padding-bottom': '0' }
    }),
    copyright: {
        'font-weight': '400',
        ...uikit.small,
        color: variable.greySuperDarkColor
    },
    dev: {
        display: 'flex',
        'flex-direction': 'row',
        'align-items': 'center'
    },
    devText: {
        ...uikit.small,
        color: variable.greySuperDarkColor,
        'margin-right': '4px'
    },
    devLink: media(width, height, 'w', {
        768: {
            ...uikit.small,
            'font-weight': '500',
            //color: variable.blueColor
            color: variable.mainColorAccent
        },
        0: {
            ...uikit.small,
            //color: variable.blueColor
            color: variable.mainColorAccent
        }
    }),
    cursorPointer: { ...uikit.pointer },
    widgetsButtonWrap: media(width, height, 'w', {
        768: {
            ...uikit.text,
            padding: '12px 16px',
            'font-weight': 400
        }
    }),
    widgetsButtonWrapMob: media(width, height, 'w', {
        1301: { display: 'none' },
        768: {
            'border-radius': '14px',
            ...uikit.alignItemsCenter,
            ...uikit.flexRow,
            'position': 'relative',
            ...uikit.small,
            'border-width': '1px',
            'border-style': 'solid',
            'border-color': variable.darkExtraTransparentColor,
            // 'margin-left': '8px',
            padding: '13px',
            'margin-right': '8px'
        },
        0: {
            display: 'flex',
            height: '24px',
            'margin-right': '8px'
        }
    }),
    widgetsButton: media(width, height, 'w', {
        768: {
            display: 'flex',
            'flex-direction': 'row'
        },
        0: { display: 'none' }
    }),
    widgetsButtonNative: media(width, height, 'w', {
        575: {
            display: 'flex',
            'flex-direction': 'row'
        },
        0: { display: 'none' }
    }),
    widgetsButtonSvg: {
        fill: variable.blueColor,
        width: '24px',
        height: '24px'
    },
    buttonAppSvg: {
        width: '28px',
        height: '31px'
    },
    btnApp: media(width, height, 'w', {
        1300: {
            padding: '8px 16px',
            'border-radius': '14px',
            display: 'flex',
            'align-items': 'center',
            'background-color': 'transparent',
            outline: 'transparent',
            position: 'relative',
            'flex-direction': 'row',
            'font-weight': '500',
            color: variable.darkColor,
            'max-height': '48px'
        },
        768: {
            padding: '8px 16px',
            'border-radius': '14px',
            display: 'flex',
            'align-items': 'center',
            'background-color': 'transparent',
            outline: 'transparent',
            position: 'relative',
            'flex-direction': 'row',
            'font-weight': '500',
            color: variable.darkColor,
            'max-height': '52px'
        },
        0: { display: 'none' }
    }),
    btnAppNative: {
        'padding-top': 6,
        'padding-bottom': 6,
        'padding-left': 16,
        'padding-right': 16,
        'border-radius': '10px',
        display: 'flex',
        'align-items': 'center',
        'background-color': 'transparent',
        position: 'relative',
        'flex-direction': 'row'
    },
    buttonAppHover: { color: variable.blueColor },
    appWrapperButtons: media(width, height, 'w', {
        768: {
            'padding-right': '16px',
            'margin-right': '16px',
            'border-right-width': '1px',
            'border-style': 'solid',
            'border-color': variable.darkExtraTransparentColor
        },
        0: { display: 'none' }
    }),

    appButtonText: {
        color: 'inherit',
        ...uikit.subtitle,
        'line-height': '1'
    },
    appButtonTextNative: {
        color: variable.blackColor,
        'font-size': 18
    },
    appButtonTextSm: {
        color: 'inherit',
        'font-size': '0.625rem',
        'line-height': '1.6'
    },
    appButtonTextSmNative: {
        color: variable.blackColor,
        'font-size': 10
    },
    widgetsButtonSvgMobile: media(width, height, 'w', {
        1301: { display: 'none' },
        768: {
            fill: variable.greyExtraDarkColor,
            width: '20px',
            height: '20px'
        },
        0: {
            fill: variable.greyExtraDarkColor,
            width: '20px',
            height: '20px'
        }
    }),
    widgetsButtonText: {
        'padding-left': '13px',
        color: 'currentColor'//variable.blueDarkColor
    },
    widgetsButtonTextHover: { color: variable.blueColor },
    widgetsButtonMobile: media(width, height, 'w', {
        1301: { display: 'none' },
        768: { 'margin-left': '12px' },
        0: {}
    }),
    widgetsButtonMob: {
        ...uikit.flexRow,
        ...uikit.alignItemsCenter
    },
    widgetsButtonMobileSvg: {
        width: '18px',
        height: '20px'
    },
    topLine: media(width, height, 'w', {
        575: {
            position: 'absolute',
            background: variable.greySuperLightColor,
            opacity: '0.5',
            'box-shadow': `0px 0px 30px ${variable.blackSuperTransparentColor}`,
            height: '80px',
            width: '100% '
        },
        0: { display: 'none' }
    }),
    officeMenuItemHoverNative: { 'background-color': variable.greyExtraLightColor },
    officeWrapItemMenu: media(width, height, 'w', {
        768: {
            display: ' flex',
            'justify-content': 'center',
            'align-items': 'center'
        },
        0: {
            display: ' flex',
            'flex-direction': 'row',
            'justify-content': 'center',
            'align-items': 'center'
        }
    }),
    /* officeMenuItemLast: {
        'background-color':variable.whiteColor,
        'flex-grow':'1',
        'z-index':'1'
    }, */
    angleMenuItems: media(width, height, 'w', {
        1301: { display: 'none' },
        0: { 'padding-right': '14px' }
    }),
    officeMenuItemImgNative: media(width, height, 'w', {
        575: {
            'margin-bottom': '10px'
        // 'width': 30
        },
        0: {}
    }),
    officeSubMenuNative: media(width, height, 'w', {
        768: {
            padding: '16px 0 0',
            position: 'absolute',
            top: 0,
            right: '-200px',
            background: variable.whiteColor,
            'border-radius': '0 24px 24px 0'
        },
        0: { padding: '16px 0 0' }
    }),
    officeSubMenuItemNative: media(width, height, 'w', {
        768: {
            padding: '10px 15px 10px 30px',
            width: '200px'
        },
        0: { padding: '10px 15px 10px 30px' }
    }),
    titleBlock: media(width, height, 'w', {
        992: {
            display: 'flex',
            'justify-content': 'space-between',
            'align-items': 'center',
            'margin-bottom': '24px'
        },
        768: {
            display: 'flex',
            'flex-direction': 'column',
            'align-items': 'flex-start',
            'margin-bottom': '24px'
        },
        0: { display: 'none' }
    }),
    title: {
        ...uikit.h5,
        color: variable.darkColor
    },
    breadCrumb: {
        display: 'flex',
        'flex-direction': 'row',
        'flex-wrap': 'wrap',
        ...uikit.small,
        color: variable.darkColor
    },
    breadcrumbslesh: {
        margin: '0 4px',
        opacity: '0.5'
    },
    breadCrumbLast: { opacity: '0.5' },
    officeMenuWrapper: {
        width: '100%',
        display: 'flex',
        'flex-direction': 'row',
        'justify-content': 'flex-end'
    },
    officeLogoWrapper: {
        display: 'flex',
        'flex-direction': 'row'
    },
    officeLogoSvg: media(width, height, 'w', {
        800: {
            fill: variable.blueColor,
            width: '105px',
            height: '30px'
        },
        0: {
            fill: variable.blueColor,
            width: '105px',
            height: '30px'
        }
    }),
    cusetomHeaderArrow: media(width, height, 'w', {
        768: { display: 'none' },
        0: {}
    }),
    officeBurger: media(width, height, 'w', {
        1301: { display: 'none' },
        768: {
            margin: '-3px 0 -3px 20px',
            display: 'flex',
            'flex-direction': 'column',
            'justify-content': 'center',
            'align-items': 'flex-end',
            border: 'none'
        },
        0: {
            width: '24px',
            height: '24px',
            margin: '-3px 0 -3px 0px',
            display: 'flex',
            'flex-direction': 'column',
            'justify-content': 'center',
            'align-items': 'center'
        }
    }),
    officeBurgerLine: media(width, height, 'w', {
        768: {
            'background-color': variable.greyExtraDarkColor,
            margin: '3px',
            width: '24px',
            height: '2px',
            'border-radius': '50px',
            transition: '0.7s'
        },
        0: {
            'background-color': variable.greyExtraDarkColor,
            margin: '2px',
            width: '18px',
            height: '2px',
            'border-radius': '50px',
            transition: '0.7s'
        }
    }),
    officeBurgerLineNative: media(width, height, 'w', {
        575: {
            'background-color': variable.greyExtraDarkColor,
            margin: '3px',
            width: '24px',
            height: '2px',
            'border-radius': '50px'
        },
        0: {
            'background-color': variable.greyExtraDarkColor,
            margin: '2px',
            width: '18px',
            height: '2px',
            'border-radius': '50px'
        }
    }),
    officeBurgerLineActive: media(width, height, 'w', {
        768: { width: '12px' },
        0: {}
    }),
    blackText: { color: variable.darkColor },

    // ===============================================================================================
    // ===============================================================================================
    // ===============================================================================================

    // Start Office Right Widget User

    officeWidgetUserHeader: media(width, height, 'w', {
        1301: {
            width: '400px',
            'border-bottom-width': '1px',
            // 'border-bottom-style' : 'solid',
            'border-bottom-color': variable.blueDarkTransparentColor,
            'padding-top': '27px',
            'padding-right': '24px',
            'padding-bottom': '0 ',
            'padding-left': '24px'
        },
        0: {
            'border-bottom-width': '1px',
            // 'border-bottom-style' : 'solid',
            'border-bottom-color': variable.blueDarkTransparentColor,
            'padding-top': '27px',
            'padding-right': '24px',
            'padding-bottom': '0 ',
            'padding-left': '24px'
        }
    }),
    userImgWrap: {
        width: '100px',
        height: '100px',
        display: 'flex',
        'align-items': 'center',
        'justify-content': 'center',
        'border-radius': '100%',
        'align-self': 'center'
    },
    userImgWrapNative: {
        width: '100px',
        height: '100px',
        display: 'flex',
        'align-items': 'center',
        'justify-content': 'center',
        'border-radius': '100px'
    },
    userAvatarImg: {},
    divWrapper: { position: 'relative' },
    formInputFocus: { border: `1px solid ${variable.blueDeepLightColor}` },
    formTitleWeb: {
        transition: 'all .5s',
        'pointer-events': 'none'
    },
    formTitleFocus: { color: variable.blueColor },
    lgBlackText: {
        'font-weight': '500',
        ...uikit.title,
        color: variable.darkColor
    },
    mentorLoginIconSvg: {
        fill: variable.whiteColor,
        width: '10',
        height: '10'
    },
    officeInfo: { 'margin-left': '8px' },
    adminMenuItemImg: media(width, height, 'w', {
        992: {
            'margin-bottom': '10px',
            width: '28px',
            height: '28px'
        },
        0: {
            'margin-bottom': '0px',
            width: '28px',
            height: '28px'
        }
    }),
    userBlockWidgetWrapperWeb: { overflow: 'auto' },
    // End Office Right Widget User

    //    modalWindow
    modalWrapper: {
        width: '480px',
        'max-width': '100%',
        background: variable.whiteColor,
        'border-radius': '20px',
        'margin-left': 'auto',
        'margin-right': 'auto',
        position: 'relative',
        display: 'flex',
        'flex-direction': 'column'
    },
    modalClose: {
        'cursor': 'pointer',
        position: 'absolute',
        top: '24px',
        right: '30px'
    },
    modalCloseSvg: {
        fill: variable.greyExtraDarkColor,
        width: '12px',
        height: '12px'
    },
    modalTitle: {
        color: variable.darkColor,
        ...uikit.title,
        'text-align': 'center',
        'font-weight': '500'
    },
    modalHeader: {
        'padding-top': '32px',
        'padding-left': '52px',
        'padding-right': '52px'
    },
    modalBody: {
        'padding-top': '16px',
        'padding-bottom': '32px',
        'padding-left': '52px',
        'padding-right': '52px'
    },
    modalBodyText: {
        'text-align': 'center',
        ...uikit.small,
        'line-height': '1.7',
        color: variable.darkHalfTransparentColor,
        'padding-bottom': '24px'
    },
    qrCod: {
        background: variable.greyExtraDarkColorTransparent,
        width: '216px',
        height: '216px',
        'border-radius': '14px',
        'margin-left': 'auto',
        'margin-right': 'auto',
        padding: '16px'
    },
    //    END modalWindow,
    // #region header right buttons
    headerRightButton: {
        display: 'flex',
        margin: '0 12px 0 -32px'
    },
    headerRightButtonSvg: {
        width: '24px',
        height: '24px',
        fill: variable.greyExtraDarkColor
    },
    officeWrapperYoutube: {
        display: 'flex',
        'flex-direction': 'column',
        'align-items': 'center',
        'justify-content': 'center',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        'z-index': 999999
    },
    officeWrapperYoutubePlayer: { 'margin-bottom': '32px' },
    officeWrapperYoutubeBg: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: 'rgba(15,19,36,0.4)',
        'backdrop-filter': 'blur(10px)'
    },
    officeWrapperYoutubeBlock: {
        background: '#fff',
        'border-radius': '20px',
        padding: '20px',
        overflow: 'auto',
        position: 'relative',
        width: '900px',
        'max-width': '100%'
    },
    officeWrapperYoutubeButton: {
        display: 'flex',
        gap: '10px',
        'justify-content': 'space-between',
        'align-items': 'center'
    },
    notificationsQuickWrapper: media(width, height, 'w', {
        768: {
            position: 'fixed',
            top: '24px',
            right: '24px',
            width: '100%',
            'max-width': '404px',
            'z-index': 9999
        },
        0: {
            position: 'fixed',
            top: '24px',
            right: '12px',
            width: '404px',
            'max-width': 'calc(100vw - 24px)',
            'z-index': 9999
        }
    })
    // #endregion header right buttons
});
export default stylesheets;