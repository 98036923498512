import { API_SERVER_URL, WSS_PORT } from './utils';
import { dataSender } from './router';
import {
    useEffect, useContext, useMemo
} from 'react';
import Pusher from 'pusher-js';
import { PusherContext } from './getContext';

export const usePusher = (userToken, lang) => {
    const optionsPusher = useMemo(() => {
        const apiUrl = new URL(API_SERVER_URL);
        return {
            key: 'key',
            dev: process.env.NODE_ENV === 'development',
            cluster: 'mt1',
            forceTLS: false,
            enabledTransports: ['ws', 'wss'],
            wsHost: apiUrl.host,
            wsPort: WSS_PORT,
            wssPort: WSS_PORT,
            scheme: apiUrl.protocol,
            authorizer: (channel) => {
                return {
                    authorize: (socketId, callback) => {
                        return dataSender(
                            `${API_SERVER_URL}/broadcasting/auth`,
                            'POST',
                            { socket_id: socketId, channel_name: channel.name },
                            (data) => {
                                callback(false, data.data);
                            },
                            () => {},
                            userToken,
                            lang
                        );
                    }
                };
            }
        };
    }, [userToken, lang]);
    let pusherClient = useMemo(() => WSS_PORT && userToken ? new Pusher(optionsPusher.key, optionsPusher) : null,
        [optionsPusher, userToken]);
    useEffect(() => {
        if (WSS_PORT && userToken) {
            window.Pusher = Pusher;
            if (process.env.NODE_ENV === 'development') {
                Pusher.logToConsole = true;
            }
        }
        return () => {
            window.Pusher = null;
        };
    }, [userToken]);
    return { optionsPusher, pusherClient };
};

// events - має бути useMemo
export const usePusherChannel = (client, events, { onMessage }) => {
    const { pusherClient } = useContext(PusherContext) || {};
    useEffect(() => {
        if (pusherClient && client && events?.length) {
            const chanel = pusherClient.subscribe(client);

            events.forEach((el) => {
                chanel?.bind(el, onMessage);
            });
        }
        return () => {
            pusherClient?.unsubscribe(client);
        };
    }, [client, events, onMessage, pusherClient]);
};