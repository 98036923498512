import React, {
    useContext, useEffect, useState, useMemo
} from 'react';
import { navigate, getData } from '../utils/router';
import UserContext, { UserProfileContext } from '../utils/getContext';
import { checkIsPartner } from '../utils/memo';
import { delToken } from '../utils/utils';
import { useRouter } from 'next/router';
import { Div } from '../../hybrid/wrappers';
import BlockedPageModal from '../components/BlockedPageModal';

const ProtectedOffice = ({ children, role = [] }) => {
    const {
        userToken, lang, roles
    } = useContext(UserContext);

    const [profileData, setProfileData] = useState(false);
    const [loaded, setLoaded] = useState(false);
    let isRole = useMemo(() => roles.some((item) => [...role].some((r) => item.name === r)), [roles, role]) ? true : 'no_roles';

    const [blockPageData, setBlockPageData] = useState(false);
    const router = useRouter();
    const getIdPage = useMemo(() => {
        switch (router.route) {
        case '/[lang]/office':
            return 1
        case '/[lang]/wallets':
            return 2
        case '/[lang]/profile':
            return 3
        case '/[lang]/reports':
            return 4
        case '/[lang]/structure':
            return 5
        case '/[lang]/treegraph':
            return 6
        case '/[lang]/orders':
            return 7
        }
    }, [router]);

    useEffect(() => {
        (async () => {
            const dataPage = await getData('/api/v1/education/blocked-page', userToken, lang);
            let blockedPages = [];
            if (dataPage?.data?.length) {
                blockedPages = dataPage?.data?.filter(i => i.closed)
            }
            if (blockedPages.length) {
                blockedPages.map(i => {
                    if (i.id == getIdPage) {
                        setBlockPageData(i)
                    }
                })
            }
        })();
    }, [userToken, lang, getIdPage]);

    const hasRoles = role.length > 0
        ? isRole
        : 'no_special_roles';

    useEffect(() => {
        if (!userToken) {
            navigate('/[lang]/authentication/[type]', '/authentication/login', lang);
        } else if (roles.length > 0){
            (async () => {
                let data;
                try {
                    data = await checkIsPartner(userToken, lang)
                } catch (e){
                    console.warn(e);
                }
                if (role.length > 0 && hasRoles !== 'no_roles' || hasRoles === 'no_special_roles') {
                    if (data?.data?.data) {
                        setProfileData(data?.data?.data);
                        setLoaded(true)
                    } else {
                        if (data === 401) {
                            await delToken(lang);//logOutFunction(lang);
                        } else if (data === 403 || data === 404) {
                            navigate('/[lang]/error/[type]', `/error/${data || 500}`, lang);
                        } else if (data === 503) {
                            navigate('/[lang]/error/[type]', '/error/503', lang);
                            return false;
                        } else {
                            setLoaded(true);
                        }
                    }
                } else {
                    navigate('/[lang]/error/[type]', '/error/403', lang);
                }
            })();
        }
    }, [userToken, lang, hasRoles, roles]);
    //if (!userToken) return null;
    if (!userToken || !hasRoles || roles && roles.length < 1) return null;
    return (
        <UserProfileContext.Provider value={{
            loaded, profileData, officePage: true
        }}>
            <Div>
                {children}
            </Div>
            {
                blockPageData?.id ? <BlockedPageModal blockPageData={blockPageData} /> : null}
        </UserProfileContext.Provider>
    );
};

export default ProtectedOffice;